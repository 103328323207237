::selection {
  background: transparent;
}

.color1 {
  color: #1bbc9b;
  font-size: 26px;
  line-height: 48px;
  font-family: "Amatic SC", cursive !important;
} /*MOUNTAIN MEADOW*/
.color2 {
  color: #c0392b; /*TALL POPPY*/
  font-size: 26px;
  line-height: 48px;
  font-family: "Amatic SC", cursive !important;
}

.card {
  color: #013243; /*SHERPA BLUE*/
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 400px;
  background: #e0e1dc;
  transform-style: preserve-3d;
  transform: translate(-50%, -50%) perspective(5000px);
  box-shadow: inset 30px 0 50px rgba(0, 0, 0, 0.5),
    10px 0 25px rgba(0, 0, 0, 0.5);
  transition: 1s;
  /* background-image: url("../../Assets/Img/Floral\ Background\ 02.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.card-hover {
  color: #013243; /*SHERPA BLUE*/
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 400px;
  background: #e0e1dc;
  transform-style: preserve-3d;
  z-index: 10;
  transform: translate(0%, -50%) perspective(5000px) rotate(8deg) scale(1.05);
  box-shadow: inset 10px 10px 60px rgba(0, 0, 0, 0.5),
    0 10px 10px rgba(0, 0, 0, 0.5);
  transition: 1s;
  /* background-image: url("../../Assets/Img/Floral\ Background\ 02.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
/* .card:hover {
  z-index: 10;
  transform: translate(0%, -50%) perspective(5000px) rotate(8deg) scale(1.05);
  box-shadow: inset 10px 10px 60px rgba(0, 0, 0, 0.5),
    0 10px 10px rgba(0, 0, 0, 0.5);
} */

.card:before {
  content: "";
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  height: 5px;
  background: #bac1ba;
  transform-origin: bottom;
  transform: skewX(-25deg);
}
.card-hover:before {
  content: "";
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  height: 5px;
  background: #bac1ba;
  transform-origin: bottom;
  transform: skewX(-25deg);
}
.card:after {
  content: "";
  position: absolute;
  top: 0;
  right: -5px;
  width: 5px;
  height: 100%;
  background: #92a29c;
  transform-origin: left;
  transform: skewY(-25deg);
}
.card-hover:after {
  content: "";
  position: absolute;
  top: 0;
  right: -5px;
  width: 5px;
  height: 100%;
  background: #92a29c;
  transform-origin: left;
  transform: skewY(-25deg);
}

.card .imgBox {
  width: 100%;
  height: 100%;
  position: relative;
  transform-origin: left;
  transition: 0.7s;
  /* background-image: url("../../Assets/Img/Floral\ Background\ 02.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.card-hover .imgBox {
  width: 100%;
  height: 100%;
  position: relative;
  transform-origin: left;
  transition: 0.7s;
  /* background-image: url("../../Assets/Img/Floral\ Background\ 02.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.card .bark {
  position: absolute;
  background: #e0e1dc;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.7s;
  /* background-image: url("../../Assets/Img/Floral\ Background\ 02.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.card-hover .bark {
  position: absolute;
  background: #e0e1dc;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 0.7s;
  /* background-image: url("../../Assets/Img/Floral\ Background\ 02.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  opacity: 1;
  transition: 0.6s;
  box-shadow: 10px 10px 60px rgba(0, 0, 0, 0.4) inset;
}

.card .imgBox img {
  min-width: 250px;
  max-width: 300px;
  max-height: 400px;
}
.card-hover .imgBox img {
  min-width: 250px;
  max-height: 400px;
  max-width: 300px;
  transform: rotateY(170deg);
}

.card-hover .imgBox {
  width: 100%;
  height: 100%;
  position: relative;
  transform-origin: left;
  transition: 0.7s;
  /* background-image: url("../../Assets/Img/Floral\ Background\ 02.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: rotateY(-170deg);
}

/* .card:hover .bark {
  opacity: 1;
  transition: 0.6s;
  box-shadow: 10px 10px 60px rgba(0, 0, 0, 0.4) inset;
} */

.card .details {
  font-family: "Indie Flower", cursive !important;
  font-size: 16px;
  position: absolute;
  top: 15px;
  left: 16px;
  box-sizing: border-box;
  padding: 0 0 0 10px;
  z-index: -1;
  margin-top: 70px;
}
.card-hover .details {
  font-family: "Indie Flower", cursive !important;
  font-size: 16px;
  position: absolute;
  top: 15px;
  left: 16px;
  box-sizing: border-box;
  padding: 0 0 0 10px;
  z-index: -1;
  margin-top: 70px;
}

.card .details p {
  font-family: "Indie Flower", cursive !important;
  font-size: 16px;
  line-height: 10px;
  transform: rotate(-10deg);
  padding: 0 0 0 20px;
}
.card-hover .details p {
  font-family: "Indie Flower", cursive !important;
  font-size: 16px;
  line-height: 10px;
  transform: rotate(-10deg);
  padding: 0 0 0 20px;
}
.card .details .wish-message {
  font-family: "Indie Flower", cursive !important;
  font-size: 16px;
  line-height: 25px;
  transform: rotate(-10deg);
  padding: 0 0 0 20px;
  max-width: 250px;
  min-width: 250px;
  min-height: 225px;
  max-height: 250px;
}
.card-hover .details .wish-message {
  font-family: "Indie Flower", cursive !important;
  font-size: 16px;
  line-height: 25px;
  transform: rotate(-10deg);
  padding: 0 0 0 20px;
  max-width: 250px;
  min-width: 250px;
  min-height: 225px;
  max-height: 250px;
}

.card .details h4 {
  text-align: center;
}
.card-hover .details h4 {
  text-align: center;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.card-hover:hover .bark .invert-title {
  transform: rotateY(180deg);
}
body:hover .rotate {
  transform: rotateY(180deg);
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 400px;
  /* border: 1px solid #f1f1f1; */
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card-hover {
  background-color: transparent;
  width: 300px;
  height: 400px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

.flip-card-hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  font-family: "Indie Flower", cursive !important;
  font-size: 16px;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  box-shadow: inset 0px 0 0px rgba(0, 0, 0, 0.5), 10px 0 15px rgba(0, 0, 0, 0.5);
  /* background-image: url("../../Assets/Img/Floral\ Background\ 02.jpg"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 8px;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: dodgerblue;
  color: #1bbc9b;
  transform: rotateY(180deg);
}

.flip-card-details {
  font-family: "Indie Flower", cursive !important;
  font-size: 16px;
  position: absolute;
  /* top: 15px;
  left: 16px; */
  box-sizing: border-box;
  /* padding: 0 0 0 10px; */
  z-index: -1;
}

.wish-message {
  font-family: "Indie Flower", cursive !important;
  font-size: 16px;
  line-height: 25px;
  padding: 0 0 0 20px;
  max-width: 250px;
  min-width: 250px;
  min-height: 225px;
  max-height: 250px;
  word-wrap: break-word;
  white-space: pre-wrap;
}
