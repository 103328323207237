@font-face {
  font-family: "Pacifico-Regular";
  src: local("Pacifico-Regular"),
    url("./fonts/icomoon/fonts/Pacifico-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Quicksand";
  src: local("Quicksand"),
    url("./fonts/icomoon/fonts/Quicksand-VariableFont_wght.ttf")
      format("truetype");
}

@font-face {
  font-family: "Playfair";
  src: local("Playfair"),
    url("./fonts/icomoon/fonts/PlayfairDisplay-VariableFont_wght.ttf")
      format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: normal;
  src: local("Lato"),
    url("./fonts/icomoon/fonts/Lato-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  src: local("Lato"),
    url("./fonts/icomoon/fonts/Lato-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 600;
  src: local("Lato"),
    url("./fonts/icomoon/fonts/Lato-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: normal;
  src: local("Lato"),
    url("./fonts/icomoon/fonts/Lato-Italic.ttf") format("truetype");
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::selection {
  color: #fff !important;
  background: #006bd6 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.reactionbar {
  height: 36px;
  width: 36px;
  transition: transform 0.2s;
}
.reactionbar:hover {
  transform: scale(1.5);
  z-index: 100;
}

@media (max-width: 600px) {
  .MuiPopper-root {
    z-index: 1500 !important;
  }
  .MuiPopover-root {
    z-index: 1500 !important;
  }
}
.t16lpgj .ejr02pv {
  margin-top: 10px;
  padding: 0 0.3em;
  position: absolute;
  z-index: 1000;
  box-sizing: content-box;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 30px 0 gainsboro;
  right: 0px !important;
  left: 0px !important;
}
.t16lpgj .e1kg9q3n {
  margin: 0 0 0.3em;
  position: relative;
  z-index: 0;
  width: 100% !important;
  height: 14em;
}

.e8k2yoa {
  margin: 0;
  padding: 0;
  /* width: 1px !important; */
  height: 1.5em;
  box-sizing: border-box;
  line-height: 1.2em;
  font-size: 1.8em;
  color: #fff;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 1.5em;
  cursor: pointer;
  border-style: hidden !important;
}
.DraftEditor-root {
  width: 100% !important;
}

.MuiInputBase-multiline {
  padding: 16.5px 14px 50px 14px !important;
}
.commentBox .MuiInputBase-multiline {
  padding: 4px 24px 4px 8px !important;
}

.t16lpgj {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 8px !important;
  box-shadow: 0px 1px 3px 0px rgb(220 220 220);
  z-index: 2;
  box-sizing: border-box;
  position: absolute;
  bottom: 2px;
  left: 1px;
  width: 99.4%;
  padding-left: 1px;
}

.RichTextEditorerror .t16lpgj {
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  background: #fff;
  border-radius: 8px !important;
  box-shadow: 0px 1px 3px 0px rgb(220 220 220);
  z-index: 2;
  box-sizing: border-box;
  position: absolute;
  bottom: 25px !important;
  left: 1px;
  width: 99.4%;
}

.alignTabCenter .MuiTabs-flexContainer {
  width: 100%;
  justify-content: space-evenly;
}

.alignTabCenter .MuiTabPanel-root {
  padding: 0px !important;
  padding-bottom: 24px !important;
}

.mnw6qvm {
  height: 200px !important;
  overflow-y: scroll;
}
.bc4rxid {
  border-radius: 8px;
}

.commentBox .e8k2yoa {
  position: absolute;
  top: 0px;
  right: 40px;
  z-index: 15;
  background: unset !important;
  height: unset !important;
  width: unset !important;
}

.m6zwb4v,
.m6zwb4v:visited {
  color: royalblue !important;
  background: none !important;
}
.MuiDivider-wrapper {
  padding-left: 4px !important;
  padding-right: 2px !important;
}
.swiper-button {
  display: flex;
  position: absolute;
  top: calc(50% - 12px);
  z-index: 10;
  cursor: pointer;
}
.image-swiper-button-prev {
  left: 0px;
}
.image-swiper-button-next {
  right: 0px;
}
.swiper-button-disabled {
  display: none;
  opacity: 0;
  pointer-events: none;
}
.rd3t-node {
  cursor: pointer;
  fill: #777;
  stroke: transparent !important;
  stroke-width: 100 !important;
}
.rd3t-leaf-node {
  cursor: pointer;
  fill: #777 !important;
  stroke: transparent !important;
  stroke-width: 100 !important;
}
.profile-card-skleton .MuiSkeleton-root {
  display: flex !important;
  transform-origin: unset !important;
  transform: unset !important;
}
.rootNodeAlways {
  opacity: 0 !important;
}
.preview audio {
  width: 150px !important;
}

#search-category-select fieldset {
  border-width: 0px !important;
}
.male-button {
  /* background: linear-gradient(
    90deg,
    #add8e6,
    #4682b4
  ); Light blue to steel blue gradient */
  /* color: #e0ffff !important; White text for contrast */
  background-color: #a7c7fc !important;
  color: #805687;
  /* padding: 10px 20px; */
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.male-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.male-button.selected {
  border-color: #4682b4; /* Highlight with steel blue border */
  box-shadow: 0px 4px 12px rgba(12, 23, 33, 0.9); /* Glow effect */
}
.female-button {
  /* background: linear-gradient(
    90deg,
    #ffc0cb,
    #ff69b4
  ); Light pink to hot pink gradient */
  background-color: #fbd6db !important;
  /* color: #ffe4e9 !important; White text for contrast */
  color: #805687;
  /* padding: 10px 20px; */
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.female-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.female-button.selected {
  border-color: #ff69b4; /* Highlight with hot pink border */
  box-shadow: 0px 4px 12px rgba(22, 9, 16, 0.9); /* Glow effect */
}
